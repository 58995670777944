import React from 'react'

function MainComp() {
    return (
        <>
            <header className="main-content">
                <p>
                    Hello, my name is Joacim Strandvide and I am a front-end
                    developer with a strong interest in programming,
                    cybersecurity, and technology in general.
                </p>
            </header>
            {/* Skills */}
            <section className="skill-container">
                <article className="skill-card">
                    <h2>Frontend Skills</h2>
                    <p>HTML5 & CSS3</p>
                    <p>Javascript</p>
                    <p>VueJS</p>
                    <p>React</p>
                    <p>Typescript</p>
                    <p>Styled Components</p>
                </article>

                <article className="skill-card">
                    <h2>Backend Skills</h2>
                    <p>NodeJS</p>
                    <p>PostgreSQL</p>
                    <p>PHP</p>
                    <p>GraphQL</p>
                </article>

                <article className="skill-card">
                    <h2>Tools</h2>
                    <p>Figma</p>
                    <p>Linux</p>
                    <p>Git & Github</p>
                    <p>Docker</p>
                    <p>MongoDB</p>
                </article>
            </section>
            {/* My Projects */}
            <h2 id="projects-title">My Projects</h2>
            <section id="projects" className="projects">
                {/* Project 1 */}
                <div className="project">
                    <div className="project-content">
                        <div className="project-image">
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    '/images/15-front.png'
                                }
                                alt="SwedishMarkers"
                            />
                        </div>
                        <div className="project-details">
                            <h2>SwedishMarkers</h2>
                            <p>Find unique locations on a map</p>
                            <p>
                                <strong>Techstack: </strong>React, react-leaflet
                            </p>
                            <div className="project-links">
                                <a
                                    href="https://joacimstrandvide.github.io/SwedishMarkers/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Live
                                </a>
                                <a
                                    href="https://github.com/joacimstrandvide/SwedishMarkers"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Github
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Project 2 */}
                <div className="project">
                    <div className="project-content">
                        <div className="project-image">
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    '/images/10-front.png'
                                }
                                alt="WhiskyPlus"
                            />
                        </div>
                        <div className="project-details">
                            <h2>WhiskyPlus</h2>
                            <p>Store that sells whiskys</p>
                            <p>
                                <strong>Techstack:</strong> VueJS, Vite
                            </p>
                            <div className="project-links">
                                <a
                                    href="https://joacimstrandvide.github.io/WhiskyPlus/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Live
                                </a>
                                <a
                                    href="https://github.com/joacimstrandvide/WhiskyPlus"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Github
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Project 3 */}
                <div className="project">
                    <div className="project-content">
                        <div className="project-image">
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    '/images/17-front.png'
                                }
                                alt="ToDo"
                            />
                        </div>
                        <div className="project-details">
                            <h2>ToDo</h2>
                            <p>Simple Reminder App</p>
                            <p>
                                <strong>Techstack:</strong> React, styled-components
                            </p>
                            <div className="project-links">
                                <a
                                    href="https://joacimstrandvide.github.io/ToDo/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Live
                                </a>
                                <a
                                    href="https://github.com/joacimstrandvide/ToDo"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Github
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Project 4 */}
                <div className="project">
                    <div className="project-content">
                        <div className="project-image">
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    '/images/9-front.png'
                                }
                                alt="CountryApi"
                            />
                        </div>
                        <div className="project-details">
                            <h2>CountryAPI</h2>
                            <p>
                                Search information about countries and their
                                national holidays
                            </p>
                            <p>
                                <strong>Techstack:</strong> HTML, CSS,
                                Javascript
                            </p>
                            <div className="project-links">
                                <a
                                    href="CountryAPI/index.html"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Live
                                </a>
                                <a
                                    href="https://github.com/joacimstrandvide/CountryAPI"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Github
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Collaboration Projects */}
            <h2 id="projects-title">Collaboration Projects</h2>
            <section id="projects" className="projects">
                {/* Project 1 */}
                <div className="project">
                    <div className="project-content">
                        <div className="project-image">
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    '/images/16-front.png'
                                }
                                alt="Gomoku Game"
                            />
                        </div>
                        <div className="project-details">
                            <h2>Gomoku</h2>
                            <p>Online Gomoku game </p>
                            <p>
                                <strong>Techstack: </strong> React,
                                styled-components, express, body-parser
                            </p>
                            <div className="project-links">
                                <a
                                    href="https://github.com/vikluund/Grupp5-Gomoku"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Github
                                </a>
                                <a
                                    href="https://github.com/MelSilgon/Grupp5-Gomoku-backend"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Github
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Project 2 */}
                <div className="project">
                    <div className="project-content">
                        <div className="project-image">
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    '/images/13-front.png'
                                }
                                alt="Chat Room"
                            />
                        </div>
                        <div className="project-details">
                            <h2>Chat Room</h2>
                            <p>Chat forum</p>
                            <p>
                                <strong>Techstack:</strong> React,
                                styled-components, axios, express, dotenv,
                                body-parser
                            </p>
                            <div className="project-links">
                                <a
                                    href="https://github.com/MartinEmson/FullstackG3"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Github
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Project 3 */}
                <div className="project">
                    <div className="project-content">
                        <div className="project-image">
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    '/images/12-front.png'
                                }
                                alt="Resurant"
                            />
                        </div>
                        <div className="project-details">
                            <h2>Resturant</h2>
                            <p>Online website for resturant</p>
                            <p>
                                <strong>Techstack:</strong> Vue, Vuex, Axios
                            </p>
                            <div className="project-links">
                                <a
                                    href="https://github.com/AlexanderAvernas/Grupp5-project"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Github
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Education */}
            <section className="box-container">
                <div className="box">
                    <article className="card">
                        <h2>College</h2>
                        <h3>2021 - 2024</h3>
                        <p>IT-Högskolan, Frontend-utvecklare</p>
                        <p>
                            Högskolan i Borås: Cybersäkerhet för uppkopplade
                            enheter (distans)
                        </p>
                        <p>
                            Kristianstad högskola: Apputveckling för Android
                            (distans)
                        </p>
                        <p>
                            Luleå Universitet: Historia A, Revolutionernas
                            tidevarv: Världen efter 1700 (distans)
                        </p>
                    </article>
                </div>

                <div className="box">
                    <article className="card">
                        <h2>High School</h2>
                        <h3>2018 - 2021</h3>
                        <p>Åva Gymnasium, Informations- och medieteknik</p>
                    </article>
                </div>
            </section>
        </>
    )
}

export default MainComp
