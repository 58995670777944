import React from 'react'

function HeaderComp() {
    return (
        <>
            <div
                className="header"
                style={{
                    backgroundImage: `url(${
                        process.env.PUBLIC_URL + '/images/background.svg'
                    })`
                }}
            >
                <div>
                    <h1 class="neon">Joacim Strandvide</h1>
                    <h1 class="flux">Front-End Developer</h1>
                </div>
                <div className="header-menu">
                    <a href="#projects">View Projects</a>
                    <a href="JoacimSCV.pdf" className="button" download>
                        Download Resume
                    </a>
                </div>
            </div>
        </>
    )
}

export default HeaderComp
