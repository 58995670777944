import React from 'react'
import HeaderComp from './components/HeaderComp'
import FooterComp from './components/FooterComp'
import MainComp from './components/MainComp'

function App() {
    return (
        <>
            <HeaderComp />
            <MainComp />
            <FooterComp />
        </>
    )
}

export default App
