import React from 'react'

function FooterComp() {
    return (
        <>
            <div className="footer">
                <p>Joacim Strandvide</p>
                <a
                    href="https://github.com/joacimstrandvide"
                    target="blank"
                    rel="noopener noreferrer"
                >
                    Github
                </a>
                <a
                    href="https://www.linkedin.com/in/joacim-strandvide-2029bb22b/"
                    target="blank"
                    rel="noopener noreferrer"
                >
                    Linkedin
                </a>
            </div>
        </>
    )
}

export default FooterComp
